import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import { SEO } from "components";

import { HomeCharity, HomeCompanies, HomeEvents, HomeHero } from "views";

function IndexPage() {
  const { home, challenges, companies } = useStaticQuery(HOME_QUERY);
  const { nodes } = challenges;
  const { companyNodes } = companies;
  const { homeData } = home;
  return (
    <>
      <SEO
        title={homeData.seo_title && homeData.seo_title.text}
        description={homeData.seo_description && homeData.seo_description.text}
        imageOpenGraph={homeData.seo_image && homeData.seo_image.url}
        imageTwitter={homeData.seo_image && homeData.seo_image.url}
        imageAlt={homeData.seo_image && homeData.seo_image.alt}
      />
      <HomeHero homeData={homeData} />
      <HomeEvents homeData={homeData} />
      <HomeCharity homeData={homeData} challenges={nodes} />
      <HomeCompanies homeData={homeData} companies={companyNodes} />
    </>
  );
}

const HOME_QUERY = graphql`
  {
    home: prismicHome {
      homeData: data {
        title {
          html
        }
        title_caption
        subtitle
        subtitle_caption
        hero_image {
          fluid(maxWidth: 1920) {
            ...GatsbyPrismicImageFluid
          }
          alt
        }

        events {
          event {
            uid
            document {
              ... on PrismicEvent {
                id
                data {
                  title {
                    text
                  }
                  sponsors {
                    name
                  }
                  excerpt
                  start
                }
              }
            }
          }
        }
        charity_title {
          text
        }
        charity_body {
          html
        }
        registration_title {
          text
        }
        registration_body {
          html
        }
        registration_button_text
        registration_button_link {
          url
        }
        not_registered_prompt
        seo_title {
          text
        }
        seo_description {
          text
        }
        seo_image {
          url
          alt
        }
      }
    }
    challenges: allPrismicCharityChallenge(
      sort: { fields: data___title___text, order: ASC }
    ) {
      nodes {
        uid
        data {
          title {
            text
          }
          goal
          goal_progress
          goal_units
          logo {
            fluid(maxWidth: 512) {
              ...GatsbyPrismicImageFluid
            }
            alt
          }
        }
      }
    }
    settings: prismicSettings {
      settingsData: data {
        ccc_logo {
          fluid(maxWidth: 512) {
            ...GatsbyPrismicImageFluid
          }
          alt
        }
      }
    }
    companies: allAirtable(
      filter: {
        data: {
          Name: { ne: "_placeholder(Please leave me be)" }
          September_Games_Last_Payment_Year: { eq: 2020 }
        }
      }
    ) {
      companyNodes: nodes {
        data {
          Name
        }
      }
    }
  }
`;

export default IndexPage;
